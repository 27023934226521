
import { Vue, Component, VModel } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { VERTICAL_LIST_SORT_DETAULT_VALUE } from '@/lib/constants';

@Component
export default class TasqsSorting extends Vue {
  @VModel({ type: String, default: VERTICAL_LIST_SORT_DETAULT_VALUE }) sortType!: any

  get isWorkTicketView() {
    return this.tasqListLevel === 'WorkTicket';
  }

  get isPadView() {
    return this.tasqListLevel === 'Pad';
  }

  get tasqListLevel() {
    return tasqsListModule.tasqListLevel;
  }
}
